// React imports
import React from 'react';

// gatbsy imports
import { PageProps, graphql } from 'gatsby';

// SEO
import SEO from 'components/seo';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

// Page views
import FinantialStatements from 'views/FinantialStatements';

type DataProps = {
    site: {
        buildTime: string;
    };
};

const FinantialPage: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <WithTheme>
        <Layout location={location}>
            <SEO title="Estados financieros" />

            <FinantialStatements />
        </Layout>
    </WithTheme>
);

export default FinantialPage;

export const query = graphql`
    {
        site {
            buildTime(formatString: "YYYY-MM-DD hh:mm a z")
        }
    }
`;
