import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '67.5%',
            marginBottom: '330px',
            marginLeft: '27.99%',
            marginTop: '237px',
            [theme.breakpoints.down(1000)]: {
                width: '70%',
                marginLeft: '22%',
            },
        },
        subGridTitle: {
            width: '68%',
            marginLeft: '27%',
            marginTop: '237px',
            [theme.breakpoints.down(1400)]: {
                width: '70%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(1200)]: {
                width: '80%',
            },
            [theme.breakpoints.down(501)]: {
                width: '70%',
                marginLeft: '21%',
                marginTop: '125px',
            },
        },
        titleContainer: {
            marginBottom: '133px',
            marginTop: '15px',
            '& #bot': { marginLeft: '59.5px' },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '70px',
                '& #bot': { marginLeft: 0 },
            },
            [theme.breakpoints.down(750)]: { marginTop: '0px' },
            [theme.breakpoints.down(650)]: { marginTop: '25px' },
            [theme.breakpoints.down(501)]: { marginTop: '15px', marginBottom: '50px' },
        },
        title: {
            fontSize: '59.5px',
            color: '#3D4975',
            letterSpacing: '7px',
            fontWeight: 900,
            marginBottom: 0,
            [theme.breakpoints.down(1000)]: {
                fontSize: '55.24px',
                letterSpacing: '6.34px',
            },
            [theme.breakpoints.down(701)]: { fontSize: '52.24px', letterSpacing: '6px' },
            [theme.breakpoints.down(650)]: { fontSize: '45px', letterSpacing: '5.5px' },
            [theme.breakpoints.down(500)]: { fontSize: '29.85px', letterSpacing: '1.5px' },
        },
        calcContainer: {
            margin: '10px 40px 420px 0px',
            height: '90%',
        },
        firstRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 0,
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
            },
        },
        downloadButton: {
            maxWidth: '197px',
            minWidth: '91px',
            maxHeight: '24px',
            marginLeft: '40px',
            marginTop: '25px',
            '& span': {
                marginLeft: '26px',
                marginRight: '26px',
                [theme.breakpoints.down(1200)]: {
                    marginLeft: '16px',
                    marginRight: '16px',
                },
                [theme.breakpoints.down(1150)]: {
                    marginLeft: '6px',
                    marginRight: '6px',
                },
                [theme.breakpoints.down(1100)]: {
                    marginLeft: '0',
                    marginRight: '0',
                },
            },
            [theme.breakpoints.down(1100)]: {
                marginLeft: '20px',
            },
            [theme.breakpoints.down(1050)]: {
                marginLeft: '10px',
            },
            [theme.breakpoints.down(1000)]: {
                marginLeft: '0px',
                marginTop: '40px',
            },
            [theme.breakpoints.down(769)]: {
                maxWidth: '65%',
                marginTop: '40px',
            },
        },
        textContainer: {
            marginTop: '25px',
            fontSize: '32px',
            color: theme.palette.primary.main,
            letterSpacing: '0.89px',
            lineHeight: '50px',
            fontWeight: 500,
            marginBottom: '0px',
            display: 'flex',
            alignItems: 'flex-start',
            '& .text': {
                fontSize: '18.3px',
                letterSpacing: '0.7px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1400)]: {
                width: '35%',
                marginRight: '2%',
            },
            [theme.breakpoints.down(1000)]: {
                width: 'auto',
                marginRight: '36vw',
            },
            [theme.breakpoints.down(769)]: {
                maxWidth: '85%',
                marginRight: '0px',
                '& .text': {
                    marginBottom: '20px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .text': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                marginTop: '0px',
                '& .text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
        },
    });
